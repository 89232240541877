import {BrowserRouter, Route, Link, Switch} from 'react-router-dom'
import Home from './Home.js'
import Listing from './Listing.js'
import './App.css'
import {useEffect} from 'react'

export default function App(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/listing" component={Listing} />
      </Switch>
    </BrowserRouter>
  )
}
