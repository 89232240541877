import {Component} from 'react'
import NasaImage from './NasaImage.js'
import LoadingPanel from './LoadingPanel.js'
import moment from 'moment'
import api_key from './api_key.js'

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
    }
  }
  render() {
    return (
      <LoadingPanel loading={this.state.loading}>
      {
        this.state.data.map(image => (
          <NasaImage {...image} key={image.date} />
        ))
      }
      </LoadingPanel>
    )
  }
  componentDidMount() {
    let end_date = moment().format('YYYY-MM-DD')
    let query = {
      api_key,
      // thumbs: true,
      start_date: '2021-05-1',
      end_date,
    }

    let queryString = new URLSearchParams(query).toString()
    let uri = `https://api.nasa.gov/planetary/apod?${queryString}`
    fetch(uri)
      .then(data => data.json())
      .then(data => {
        this.setState({data: data.reverse(), loading: false})
        return data
      })
  }
}
