import {Component} from 'react'
import NasaImage from './NasaImage.js'
import LoadingPanel from './LoadingPanel.js'
import moment from 'moment'
import api_key from './api_key.js'

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      active: 0,
      loading: true,
    }
  }
  back() {
  let active = Math.max(0, this.state.active - 1)
    this.setState({active})
  }
  forward() {
    let active = Math.min(this.state.data.length - 1, this.state.active + 1)
    this.setState({active})
    if(active >= this.state.data.length - 1) {
      this.loadMore()
    }
  }
  handleKey(e) {
    if(e.key === 'ArrowRight') {
      this.forward()
    } else if(e.key === 'ArrowLeft') {
      this.back()
    }
  }
  render() {
    let forwardDisabled = this.state.active >= this.state.data.length - 1 ? 'disabled' : ''
    let backDisabled = this.state.active === 0 ? 'disabled' : ''
    let wide = matchMedia('(min-width: 800px)').matches
    let backClass = wide ? 'fixed-left-center' : 'fixed-left-bottom'
    let forwardClass = wide ? 'fixed-right-center' : 'fixed-right-bottom'
    return (
      <LoadingPanel loading={this.state.loading} className="center full-screen">
          <>
            <div
              className={`button ${backClass} ${backDisabled}`}
              onClick={this.back.bind(this)}
            >
              &lt;
            </div>
            <div
              className={`button ${forwardClass} ${forwardDisabled}`}
              onClick={this.forward.bind(this)}>
              &gt;
            </div>
            <div className="carousel">
              <div className="carousel-wrapper"
                style={{ transform: `translate3d(${-this.state.active * 100}vw, 0, 0)` }}
              >
                {
                  this.state.data.map(image => (
                    <div className="slide" key={image.date}>
                      <NasaImage {...image} />
                    </div>
                  ))
                }
              </div>
            </div>
          </>
      </LoadingPanel>
    )
  }
  loadMore() {
    let date = moment().subtract(this.state.data.length, 'days').format('YYYY-MM-DD')

    let query = {
      api_key,
      date,
    }

    let queryString = new URLSearchParams(query).toString()
    let uri = `https://api.nasa.gov/planetary/apod?${queryString}`
    fetch(uri)
      .then(data => data.json())
      .then(data => {
        this.setState({data: this.state.data.concat(data)})
        return data
      })
  }
  componentDidMount() {
    // Date must be between Jun 16, 1995 and Jun 18, 2021.
    let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
    let today = moment().format('YYYY-MM-DD')

    let query = {
      api_key,
      // date: this.props.date || '',
      // thumbs: true,
      start_date: yesterday,
      end_date: today,
    }

    let queryString = new URLSearchParams(query).toString()
    let uri = `https://api.nasa.gov/planetary/apod?${queryString}`

    fetch(uri)
      .then(data => data.json())
      .then(data => {
        this.setState({data: data.reverse(), loading: false})
        return data
      })
      document.documentElement.addEventListener('keyup', this.handleKey.bind(this))
  }
  componentWillUnmount() {
    document.documentElement.removeEventListener('keyup', this.handleKey.bind(this))
  }
}
