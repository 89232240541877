import image from './loading.svg'
let altText = 'loading please wait'

export default function LoadingPanel(props) {
  if(props.loading) {
    return (
      <div className={props.className}>
        <img src={image} alt={altText} />
      </div>
    )
  }
  return (
    <>
      {props.children}
    </>
  )
}
