import {useState} from 'react'


function NasaImage(props) {
  const [expanded, setExpanded] = useState(false)

  const style = {
    title: {
      fontSize: '1.5em',
      fontWeight: 800,
      padding: 16,
    },
    image: {
      backgroundImage: `url(${props.url})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: '98vw',
      height: '50vh',
    },
    popupExpanded: {
      backgroundImage: `url(${props.url})`,
      backgroundColor: 'rgba(0,0,0,.75)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      position: 'fixed',
      top: 0,
      width: '98vw',
      height: '98vh',
      padding: '1vh 1vw',
      transition: 'ease 500ms',
      zIndex: 100,
    },
    popupContracted: {
      backgroundImage: `url(${props.url})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      position: 'fixed',
      top: 0,
      width: 0,
      height: '98vh',
      margin: '1vh 1vw',
      transition: 'ease 500ms',
    },
    video: {
      width: '50vw',
      height: '50vh',
    },
    sectionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      flexDirection: 'column',
    },
    text: {
      fontSize: '1.1em',
      padding: '2vh 10vw',
    },
  }

  return (
    <div style={{...style.sectionContainer, ...props.style}} className={props.className}>
      <div style={style.title}>{props.title} ({props.date})</div>
      {props.media_type === 'image' &&
        (<div
          style={style.image}
          alt={props.title}
          onClick={() => setExpanded(!expanded)}
          />)
      }
      {props.media_type === 'image' &&
        (<div
          style={expanded ? style.popupExpanded : style.popupContracted}
          alt={props.title}
          onClick={() => setExpanded(!expanded)}
          />)
      }
      {props.media_type === 'video' &&
        (<iframe src={props.url} title={props.title} style={style.video} alt={props.title} frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen />)
      }
        <div style={style.text}>
          {props.explanation}
        </div>
      </div>
    )
}

export default NasaImage
